import React from "react";
import Slider from "src/@components/slider/slider.component";

export default class LogosSliderComponent extends React.Component<LogosSliderProps> {
    static defaultProps : Partial<LogosSliderProps> = {
        renderLogoSlide: () => null,
    }

    render() {
        const { logos } = this.props;

        const initialSelection = logos.length < 6 ? Math.floor(logos.length / 2) : 0;

        return <>
            <Slider
                style={this.props.style}
                slidesPerView={6}
                spaceBetween={10}
                autoHeight={true}
                initialSlide={initialSelection}
                centeredSlides={logos.length < 6}
                autoplay={false}
                navigation={true}
                breakpoints={{
                    10: { slidesPerView: 2, grid: { rows: 1, fill: "row" }, centeredSlides: false, initialSlide: 0 },
                    480: { slidesPerView: 2, grid: { rows: 1, fill: "row" }, centeredSlides: false },
                    770: { slidesPerView: 3, grid: { rows: 1, fill: "row" }, centeredSlides: false },
                    900: { slidesPerView: 4, grid: { rows: 1, fill: "row" }, centeredSlides: false },
                    1100: { slidesPerView: 6, grid: { rows: 1, fill: "row" } },
                }} >
                {logos.map((logo, idx) => this.props.renderLogoSlide(logo, idx))}
            </Slider>
        </>
    }
}