import React from "react";

type BackgroundImageWrapperProps = {
    children: React.ReactNode | undefined;
    className?: string;
    style?: React.CSSProperties & TCSSVars;
    backgroundImage?: string;
    overlayColor?: string;
    backgroundColor?: string;
}

export default class BackgroundImageWrapper extends React.Component<BackgroundImageWrapperProps> {
    static defaultProps = { fullwidth: false, overlayColor: "" }

    get background_image() {
        if (!this.props.backgroundImage) return undefined;
        return `url(https://ik.imagekit.io/spotlio/fetch/tr:w-1920/https://public-assets.spotliodata.com/${this.props.backgroundImage})`
    }

    get backgroundImageAndOverlayStyles(){
        let styles = "";
        if(this.background_image) styles += this.background_image
        if(this.overlayColor) styles += `, linear-gradient(${this.overlayColor},${this.overlayColor})`
        return styles;
    }

    get overlayColor (){
        return this.props.overlayColor;
    }

    get overlayStyle(){
        if(this.overlayColor) return `, linear-gradient(${this.overlayColor},${this.overlayColor})`
    }

    get style() {
        const outterStyles = this.props.style || {};
        const bgstyle = this.backgroundImageAndOverlayStyles;
        const newstyles: React.CSSProperties = {
            ...outterStyles,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundBlendMode: "overlay",
        }
        if (bgstyle !== "") newstyles.background = bgstyle;
        return newstyles;
    }

    render() {

        return <div style={{ backgroundColor: this.props.backgroundColor }}>
            <div style={this.style} className={`w-full`}>{this.props.children}</div>
          </div>
    }
}